// Spoiler
$('body:not(.su-other-shortcodes-loaded)').on('click', '.su-spoiler-title', function (e) {
    var $title = $(this),
        $spoiler = $title.parent();
    // Open/close spoiler
    $spoiler.toggleClass('su-spoiler-closed');
    // Close other spoilers in accordion
    $spoiler.parent('.su-accordion').children('.su-spoiler').not($spoiler).addClass('su-spoiler-closed');
    // Scroll in spoiler in accordion
    if ($(window).scrollTop() > $title.offset().top) $(window).scrollTop($title.offset().top - $title.height());
    e.preventDefault();
});

$(function() {

    var ww = $(window).width();

    //@Benjamin, js datepicker
    'use strict';

    var language_codes = [];
    language_codes['fr'] = 'fr-FR';
    language_codes['nl'] = 'nl-NL';
    language_codes['en'] = 'en-GB';

    $('[data-toggle="datepicker"]').datepicker({
        format: 'dd/mm/yyyy',
        language: language_codes[lang],
        weekStart: 1,
        autoPick: true
    });

    $("input[type='radio'][name='delivery']").change(function() {
        if($(this).val()=='box') {
            $('#deliveryAddress').show();
        } else {
            $('#deliveryAddress').hide();
        }
    });

    // Ouvrir la popup
    $('a.popup').click(function(){
        $('body').addClass('fixed');
        $('#popup').addClass('active');

        return false;
    });

    // Fermer la popup
    $('body').click(function(event) {
 	    if(!$(event.target).closest('#popup .container').length) {
 	        $('body').removeClass('fixed');
            $('#popup').removeClass('active').html('');
 	    } else {
            if($(event.target).closest('.close-popup').length) {
                $('body').removeClass('fixed');
                $('#popup').removeClass('active').html('');
            }
 	    }
 	});

    if (ww > 700) {
        if ($('.filters').length > 0) {
            var fsh = $('.filters').offset(),
                hf  = $('.filters').outerHeight();

            $(window).scroll(function(){
                if($(window).scrollTop() > fsh.top){
                    $('.filters').addClass('fixed');
                    $('.filters + .item-list').css('margin-top', hf)
                } else {
                    $('.filters').removeClass('fixed');
                    $('.filters + .item-list').css('margin-top', 0)
                }
            });

        }
    }
    
    // FILTRES ON MOBILE DEVICE
    
    $('.filters > h3').click(function(){
	    $(this).toggleClass('active');
	    $('.filters form').slideToggle('fast');
	    return false;
    });

    $(window).resize(function(){
        ww = $(window).width();
        if (ww > 700) {
            if ($('.filters').length > 0) {
                var fsh = $('.filters').offset(),
                    hf  = $('.filters').outerHeight();

                $(window).scroll(function(){
                    if($(window).scrollTop() > fsh.top){
                        $('.filters').addClass('fixed');
                        $('.filters + .item-list').css('margin-top', hf)
                    } else {
                        $('.filters').removeClass('fixed');
                        $('.filters + .item-list').css('margin-top', 0);
                    }
                });

            }
        } else {
            if ($('.filters').length > 0) {
                $('.filters').removeClass('fixed');
                $('.filters + .item-list').css('margin-top', 0);
            }
        }

    });

    // New reservation login.

    $('#login-button').on('click', function () {

        $('#login-button').slideUp();
        $('#login-form').slideDown();

        $('#register-button').slideDown();
        $('#register-form').slideUp();
    });

    $('#register-button').on('click', function () {

        $('#login-button').slideDown();
        $('#login-form').slideUp();

        $('#register-button').slideUp();
        $('#register-form').slideDown();
    });

    // Hide or show "from" and "to" hour depending of bath entries or not.

    $('.reservation-bar select[name="filter"]').on('change', function() {

        var filter = $(this).val();

        if(filter === 'bath_entry') {
            $('.reservation-bar select[name="from"]').parent().hide();
            $('.reservation-bar select[name="to"]').parent().hide();
            $('.reservation-bar button[type="submit"]').text(see_availabilities);
        } else {
            $('.reservation-bar select[name="from"]').parent().show();
            $('.reservation-bar select[name="to"]').parent().show();
            $('.reservation-bar button[type="submit"]').text(see_packages_and_treatments);
        }
    });

    $('.book .bath_entries .items .line-item input').on('change', function() {
        $('.book .bath_entries .items .line-item .info').hide();
        $(this).siblings('.info').show();
    });

    // Mollie status check
    if($('#order-status').length) {
        checkOrderStatus();
    }

    // Mollie status check
    if($('#reservation-status').length) {
        checkReservationStatus();
    }

});

function checkOrderStatus()
{
    var order = $('#order-status').data('order');

    $.get('/order/'+order+'/get_status', function(data) {

        console.log(data.status);

        if(data.status) {

            switch (data.status) {
                case 'paid':
                    window.location = '/order/confirmation';
                    break;
                case 'canceled':
                    window.location = '/payment';
                    break;
            }
        } else {
            // Todo: vérifier data.paid ?
            setTimeout(checkOrderStatus, 3000);
        }
    });
}

function checkReservationStatus()
{
    var reservation = $('#reservation-status').data('reservation');

    $.get('/reservation/'+reservation+'/get_status', function(data) {

        console.log(data.status);

        if(data.status) {

            switch (data.status) {
                case 'paid':
                    window.location = '/reservation/confirmation';
                    break;
                case 'canceled':
                    window.location = '/reservation/payment';
                    break;
            }
        } else {
            // Todo: vérifier data.paid ?
            setTimeout(checkReservationStatus, 3000);
        }
    });
}